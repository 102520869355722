<template>
  <section>
    <transition name="fade">
      <div class="loading_new " v-if="$store.getters.loading" id='loadingNew'>
        <div class="loading_new ">
          <img src="../assets/img/etners-main/loading_new.gif" />
        </div>
        <div class="dimmed"></div>
      </div>
    </transition>

    <!--//loading-->
  </section>
</template>

<script>
export default {
  name: 'Loading',
  data () {
    return {
      show: false
    }
  },
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s
}
.fade-leave-active {
  transition: opacity 0.3s
}

.fade-enter,
.fade-leave-to
{
  opacity: 0
}
</style>
